import React, { useState, useEffect } from "react";
import FrontPage from "../../Components/Services/FrontPage";
import Filtros from "../../Components/Services/Filtros";
import ConceptService from "../../Components/Services/ConceptService";
import Galeryv2 from "../../Components/Services/Galeryv2";
import UnderMaintenance from "../UnderMaintenance";
import { Helmet } from "react-helmet";
const Interactive = () => {
  const galeries = [
    {
      name: "economy",
      url: [
        {
          url: "/assets/img/services/Galeria/Interactive/Photo-2.png",
        },
        {
          url: "/assets/img/services/Galeria/Interactive/Photo-1.png",
        },
        {
          url: "/assets/img/services/Galeria/Interactive/Photo.png",
        },
      ],
    },
  ];
  const [galery, setGalery] = useState(galeries[0]);
  const [path, setPath] = useState(window.location.pathname);

  useEffect(() => {
    window.scrollTo(0, 0);
    if (window.innerWidth > 1024) {
      const activeNot = document.getElementsByClassName("activeService");
      if (activeNot.length == 1) {
        activeNot[0].classList.remove("activeService");
      }

      const active = document.getElementById(path.split("/").join(""));
      active.classList.add("activeService");
      const active2 = document.getElementById("services");
      active2.classList.add("activeli");
    }
  }, []);
  const renderConceptServicesContent = () => {
    return (
      <ConceptService>
        <div className="title">
          <h2>Our Interactive</h2>
          <h3>Services</h3>
        </div>
        <p className="interactive">
          Our interactive experience offering is ideal for organizing and
          creating highly-specialized marketing campaigns for the real estate
          industry all in one platform. By integrating the marketing and sales
          experiences, we are able to open a dialogue for cross-functional
          teamwork, and by doing so we have sharpened the user experience
          between the development, leasing and marketing teams. Interactive
          applications are compatible with smartphones, desktops and tablets.
        </p>
      </ConceptService>
    );
  };
  return (
    <div className="body-content">
      <Helmet>
        <title>Interactive | 3D World renderings, Inc.</title>
        <meta
          name="description"
          content="Transform real estate marketing with our interactive platform. Seamlessly integrate marketing and sales, fostering cross-functional teamwork and enhancing the user experience. Compatible with smartphones, desktops, and tablets."
        />
      </Helmet>
      <FrontPage
        alt={"Interactive Services - 3D World renderings"}
        portada={
          "/assets/img/services/Galeria/Interactive/Interactive_Services-3D_World_renderings_Inc.jpg"
        }
        name={"Interactive"}
        subtitule={"Services"}
      >
        {renderConceptServicesContent()}
      </FrontPage>
      {renderConceptServicesContent()}
      <UnderMaintenance white={true}></UnderMaintenance>
      {/* <Filtros>
                <ul>
                    <li className='active'>All</li><div></div>
                    <li>Residential</li><div></div>
                    <li>Commercial</li><div></div>
                    <li>Cultural</li>
                </ul>
            </Filtros>
            <Galeryv2 galery={galery}></Galeryv2> */}
    </div>
  );
};

export default Interactive;
