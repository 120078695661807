import React, { useRef, useState, useEffect } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
// import "./styles.css";

// import required modules
import { Pagination, Navigation } from "swiper";

const Slider = () => {
  const [slides, setSlides] = useState(1);
  useEffect(() => {
    if (window.screen.width < 550) {
      setSlides(1);
    }
  }, []);

  return (
    <>
      <Swiper
        slidesPerView={slides}
        pagination={{
          clickable: true,
        }}
        spaceBetween={30}
        modules={[Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
          <h3>We Listen</h3>
          <p>
            At our first meeting, your ideas, needs, goals and expectations for
            your project take center stage. Our objective is to achieve a
            thorough understanding of the work at hand so that we can find the
            solutions you seek. From the very beginning, we establish a culture
            of open communication and confidence – the more you share, the
            better the result. Transparency and collaboration are key. We work
            within the constraints of your reality and budget. Provide us with a
            range you are willing to commit to and we will make sure that you
            get the most out of your investment. We then provide you with a
            hand-picked team from our many experienced project managers, brand
            strategists, copywriters, 3D artists, CGI artists, graphic designers
            and technologists. Our specialized team of creatives will stick with
            you, through thick and thin, to the completion of your project.
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <h3>We Create</h3>
          <p>
            The 3DW approach is very hands-on. From the tailoring of each
            client’s specialized team to the constant self-evaluation of our
            work and services, we do everything in our power to ensure that our
            output is in line with our high-quality standards. Our directors
            work personally with all clients at some point during the execution
            phase and expertly curate delivery packages with the aim of enabling
            customer success. No work leaves our studio without our founder’s
            careful inspection, approval and finishing touches. We pride
            ourselves on our comprehensive understanding of the market,
            attention to detail, and on the highly personalized service we
            extend to all of our clients. In today’s digital age, we understand
            the importance of flexibility. That is why we’re always available
            for a face-to-face meeting, video or phone call.
          </p>
        </SwiperSlide>
        <SwiperSlide>
          <h3>We Deliver</h3>
          <p>
            Good design takes time. We’ve tried to do it the fast and easy way -
            trust us, it doesn’t work. We stick to the timeline we have mutually
            agreed upon and established during the process. We maintain open
            communication throughout the development of your project to ensure
            efficient, quality work. Once we have reached the end of the
            timeline, received and updated all of your feedback and made all of
            the necessary adjustments as per the agreed number of revisions, the
            creation process is complete. 3DW delivers the final product to you
            as a proud design partner and project collaborator. We guarantee
            your full satisfaction with the results of our partnership and with
            our high-quality work, along with an incomparable sense of
            fulfillment; all on the expected date. We always deliver on time,
            every time.
          </p>
        </SwiperSlide>
      </Swiper>
    </>
  );
};

export default Slider;
