import styled from "styled-components";

const HeadingBlock = styled.div`
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 36px;
  margin-top: 1.75rem;
  @media (min-width: 1024px) {
    display: none;
  }
  @media (max-width: 550px) {
    margin-top: 22px;
    padding-top: 22px 0 0;
  }
  @media (max-width: 390px) {
    margin-top: 0;
    padding-right: 17px;
    padding-left: 30px;
  }
`;

const HeadingTitle = styled.h2`
  color: black;
  font-family: "HelveticaNeueBold";
  font-weight: 700;
  letter-spacing: -0.03em;
  display: flex;
  flex-direction: column;
  font-size: 36px;

  &::after {
    content: " ";
    margin: 40px 0;
    width: 100px;
    height: 2px;
    background-color: black;
    @media (max-width: 390px) {
      margin: 30px 0px;
    }
  }
`;

const HeadingSubtitle = styled.p`
  color: #6c6c6c;
  width: 70%;
  font-size: 18px;
  @media (max-width: 550px) {
    width: 100%;
    margin-bottom: 10px;
    font-size: 18px;
  }
`;

export { HeadingBlock, HeadingTitle, HeadingSubtitle };
