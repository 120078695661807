import styled from "styled-components";

export const WhatWeDoContainer = styled.div`
  background-color: black;
  margin: 0 auto;

  @media (max-width: 550px) {
    width: calc(100% - 30px);
  }

  .overlay-image {
    position: absolute;
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    z-index: 0;
    opacity: 1;
    transition: opacity 0.3s;
    object-fit: cover;
    &.is-hidden {
      opacity: 0;
    }
  }

  .container {
    display: flex;
    flex-direction: column;
    padding: 30px 20px;
    color: white;
    position: relative;
  }
  h3 {
    font-family: "HelveticaNeueBold";
    text-transform: capitalize;
    font-weight: 700;
    letter-spacing: -0.03em;
    font-size: 35px;
  }
  p {
    font-family: "HelveticaNeueRoman";
    font-size: 16px;
    letter-spacing: -0.03em;
    font-weight: 400;
  }
  .services {
    margin-top: 189px;
    display: flex;
    flex-direction: column;
    align-items: start;
    position: relative;
    z-index: 1;
    .yellow-indicator {
      position: absolute;
      left: 0;
      height: 4px;
      background-color: #ffff02;
      transition: all 0.3s ease-in-out;
      transform: translateY(100%);
    }
    a {
      background: transparent;
      border: 0;
      color: white;
      outline: 0;
      font-size: 45px;
      line-height: 53.68px;
      border-bottom: 4px solid transparent;
      font-family: "HelveticaNeueRoman";
      font-weight: 400;
      letter-spacing: -0.03em;
      cursor: pointer;
    }
  }
`;
