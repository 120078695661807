import React from "react";
import styled, { css } from "styled-components";
import ConceptService from "./ConceptService";

const Portada = styled.div`
  width: 100%;
  height: 500px;
  position: relative;
  @media (min-width: 1600px) {
    height: 600px;
  }
  .background-black {
    width: 100%;
    height: 100%;
    position: absolute;
    background: rgba(0, 0, 0, 0.4);
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .title {
    position: absolute;
    top: 45%;
    left: 90px;
    display: flex;
    @media (max-width: 1024px) {
      left: 40px;
    }
    @media (max-width: 550px) {
      top: 30%;
    }
    h1 {
      color: white;
      font-size: 60px;
      font-weight: normal;
      font-family: "NeueHaasDisplayMedium";
      padding-right: 20px;
      @media (max-width: 550px) {
        font-size: 33px;
        padding-right: 10px;
      }
    }
    h2 {
      color: white;
      font-size: 60px;
      font-weight: normal;
      font-family: "HelveticaNeue";
      padding: 0;
      @media (max-width: 550px) {
        font-size: 50px;
      }
    }
  }
`;

const Concepto = styled.div`
  width: 100%;
  margin-top: 1.75rem;
  padding: 120px 100px;
  background-color: #f1f0eb;
  @media (max-width: 1024px) {
    display: none;
  }
  @media (max-width: 1024px) {
    padding: 40px;
    margin-top: 1.5rem;
  }
  .title {
    display: flex;
    @media (max-width: 550px) {
      flex-direction: column;
    }
    h2 {
      padding: 0 0 25px 0;
      color: black;
      font-family: "NeueHaasDisplayMedium";
      display: flex;
      flex-direction: column;
      @media (max-width: 550px) {
        padding: 0 0 15px 0;
      }
      &::after {
        content: " ";
        margin-top: 10px;
        width: 100px;
        height: 2px;
        background-color: black;
      }
    }
    h3 {
      color: black;
      font-family: "HelveticaNeue";
      font-weight: 300;
      padding-left: 20px;
      @media (max-width: 550px) {
        padding-left: 0;
        padding: 0 0 20px;
        font-size: 25px;
      }
    }
  }
  p {
    width: 833px;
    color: #6c6c6c;
    @media (max-width: 1024px) {
      width: 100%;
    }
    /* &:nth-child(2){
            margin-bottom: 20px;
        } */
  }
  .about {
    width: 900px;
    @media (max-width: 1024px) {
      width: 100%;
    }
    &:nth-child(2) {
      margin-bottom: 20px;
    }
  }
  .rendering {
    width: 900px;
    &:nth-child(2) {
      margin-bottom: 20px;
    }
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .virtualR {
    width: 833px;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .animation {
    width: 900px;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .interactive {
    width: 880px;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .branding {
    width: 895px;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
  .vfx {
    width: 900px;
    @media (max-width: 1024px) {
      width: 100%;
    }
  }
`;
const PortadaV2 = styled(Portada)`
  @media (max-width: 1024px) {
    height: 100vh;
    min-height: 600px;
  }
  .title {
    z-index: 5;
    line-height: 39px;
    @media (max-width: 1024px) {
      position: relative;
      top: auto;
      left: auto;
      display: block;
      * {
        display: inline-block;
        padding-right: 15px !important;
        &:last-child {
          padding-right: 0 !important;
        }
      }
    }
    @media (max-width: 390px) {
      line-height: 32px;
    }
    h1 {
      font-size: 50px;
      font-weight: 700;
      font-family: "HelveticaNeueBold";
      letter-spacing: -0.03em;
      @media (max-width: 390px) {
        font-size: 42px;
      }
    }
    h2 {
      font-size: 50px;
      font-weight: 700;
      letter-spacing: -0.03em;
      @media (max-width: 390px) {
        font-size: 42px;
      }
    }
  }
  .background-black {
    top: 0;
    left: 0;
    z-index: 1;
  }
  img {
    position: absolute;
    top: 0;
    left: 0;
  }
  .content {
    padding: 35px;
    padding-top: 120px;
    padding-bottom: 60px;
    color: white;
    position: relative;
    z-index: 1;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (max-width: 550px) {
      padding-top: 140px;
    }
    @media (max-width: 390px) {
      padding-bottom: 35px;
      padding-top: 125px;
    }
    .content-description {
      @media (min-width: 1024px) {
        display: none;
      }
      *:not(:last-child) {
        margin-bottom: 0px;
      }
      ${Concepto} {
        position: relative;
        top: auto;
        left: auto;
        padding: 0;
        margin: 0;
        background-color: transparent;
        @media (max-width: 1024px) {
          display: block;
        }
        .title {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
        }
        h2 {
          color: white;
          font-size: 25px;
          padding-right: 6px !important;
          ::after {
            display: none;
          }
          @media (max-width: 1024px) {
            font-size: 25px;
          }
          @media (max-width: 550px) {
            font-size: 22px;
          }
          @media (max-width: 390px) {
            font-size: 20px;
          }
        }
        h3 {
          color: white;
          padding-left: 0;
          display: block;
          @media (max-width: 1024px) {
            font-size: 25px;
          }
          @media (max-width: 550px) {
            font-size: 22px;
          }
          @media (max-width: 390px) {
            font-size: 20px;
            padding: 0 0 16px;
          }
        }
        p {
          color: white;
          padding-bottom: 20px;
          @media (max-width: 550px) {
            padding-right: 35px;
            line-height: 21.47px;
          }
          @media (max-width: 390px) {
            padding-right: 17px;
            font-size: 16px;
          }
        }
      }
    }
  }
`;
const Filtrado = styled.div`
  width: 100%;
  background-color: #434343;
  padding: 60px;
  @media (min-width: 1600px) {
    padding: 60px;
  }
  @media (max-width: 1335px) {
    padding: 60px;
  }
  @media (max-width: 1024px) {
    padding: 40px;
  }
  @media (max-width: 1024px) {
    margin-top: 15px;
  }
  @media (max-width: 430px) {
    padding: 60px 20px 60px;
    margin: 15px 0px 15px;
    width: 100%;
  }
  @media (max-width: 390px) {
    padding: ${(props) =>
      props.branding ? "60px 22px 60px" : "60px 20px 60px"};
  }
  .filters-container {
    overflow: hidden;
  }
  ul {
    width: 1329px;
    display: flex;
    flex-wrap: wrap;
    column-gap: 29px;
    margin: -10px 0px;
    @media (min-width: 1600px) {
      column-gap: 30px;
      width: 1415px;
    }
    @media (min-width: 1800px) {
      column-gap: 49.2px;
      width: 1629px;
    }
    @media (max-width: 1550px) {
      column-gap: 29px;
    }
    @media (max-width: 1485px) {
      column-gap: 22px;
      width: 1260px;
    }
    @media (max-width: 1410px) {
      column-gap: 17px;
      width: 1200px;
    }
    @media (max-width: 1370px) {
      column-gap: 15px;
      width: 1160px;
    }
    @media (max-width: 1335px) {
      column-gap: 14px;
    }
    @media (max-width: 1280px) {
      column-gap: 14px;
      width: auto;
    }
    @media (max-width: 1024px) {
      column-gap: 15px;
      width: auto;
      margin-left: -15px;
      gap: 15px;
    }
    @media (max-width: 550px) {
      margin-left: -7px;
      gap: 7px;
      padding: 5px 0px 10px;
    }
    @media (max-width: 390px) {
      margin-left: -6px;
      gap: 6px;
    }
    word-wrap: break-word;
    align-items: center;
    li {
      color: #787878;
      list-style: none;
      font-size: 35px;
      font-family: "HelveticaNeue";
      display: flex;
      align-items: flex-end;
      line-height: 27px;
      margin: 10px 0;
      word-wrap: break-word;
      padding-bottom: 10px;
      @media (max-width: 1024px) {
        font-size: 27px;
        padding-left: 15px;
        border-left: 2px solid #787878;
        border-bottom: 4px solid transparent;
        padding-bottom: 0;
      }
      @media (max-width: 550px) {
        font-size: 22px;
        padding-left: 7px;
        margin: 1px 0;
        border-left: 1px solid #787878;
        border-bottom: 0px solid transparent;
      }
      @media (max-width: 390px) {
        padding-left: 6px;
      }
      /* &.active {
        color: white;
        text-decoration: none;
        border-bottom: 4px solid #ffff02;
        font-weight: 700;
        a {
          color: white;
        }
      } */

      a {
        color: #787878;
        list-style: none;
        font-size: 33px;
        font-family: "HelveticaNeue";
        display: flex;
        align-items: flex-end;
        line-height: 27px;
        word-wrap: break-word;
        @media (min-width: 1600px) {
          font-size: 35px;
          padding-bottom: 8px;
        }
        @media (max-width: 1600px) {
          padding-bottom: 8px;
        }
        @media (max-width: 1024px) {
          font-size: 25px;
          padding-bottom: 2px;
          border-bottom: 4px solid transparent;
        }
        @media (max-width: 550px) {
          font-size: 18px;
          padding-bottom: 0px;
          border-bottom: 3px solid transparent;
          margin: 0;
        }
        @media (max-width: 375px) {
          font-size: 17px;
        }
        @media (max-width: 360px) {
          font-size: 16px;
          line-height: 20px;
          border-bottom: 2px solid transparent;
        }
        &:hover {
          color: white;
          cursor: pointer;
        }
      }
      &.active {
        a {
          border-bottom: 4px solid #ffff02;
          color: white;
          text-decoration: none;
          @media (max-width: 550px) {
            border-bottom: 3px solid #ffff02;
          }
          @media (max-width: 390px) {
            border-bottom: 2px solid #ffff02;
          }
        }
      }
    }
    div {
      width: 2px;
      height: 35px;
      background-color: #787878;
      margin: 0;
      @media (max-width: 1024px) {
        height: 25px;
        display: none;
      }
      @media (max-width: 550px) {
        height: 18px;
        width: 1px;
      }
    }
  }
`;
const GaleryGrid = styled.div`
  columns: 3;
  padding: 1.75rem;
  column-gap: 1.75rem;
  position: relative;
  column-fill: balance;
  @media (max-width: 1024px) {
    columns: 2;
  }
  @media (max-width: 550px) {
    columns: 1;
    column-gap: 1rem;
    padding: 15px 15px 15px;
  }
  @media (max-width: 430px) {
    padding: 0px 15px 15px;
  }
  div {
    display: inline-block;
    margin-bottom: 1.75rem;
    position: relative;
    width: 100%;
    height: 50%;
    @media (max-width: 550px) {
      margin-bottom: 1rem;
    }
    img {
      width: 100%;
      object-fit: cover;
    }
    .skeleton-image {
      height: 380px;
      @media (max-width: 1024px) {
        height: 200px;
      }
      @media (max-width: 550px) {
        height: 120px;
      }
    }
    button {
      width: 100%;
      cursor: pointer;
      border: none;
      background-color: transparent;
    }
  }
  .bottom {
    position: absolute;
    background: white;
    height: 200px;
    bottom: 0;
    left: 0;
    margin: 0;
  }
`;
const Galleryv2 = styled.div`
  width: 100%;
  padding: 0 0 1.75rem;
  @media (max-width: 550px) {
    padding: 15px 15px 15px;
  }
  @media (max-width: 430px) {
    padding: 0px 15px 15px;
  }
  img {
    width: 100%;
    padding: 1.75rem 0 0;
    cursor: pointer;
    @media (max-width: 1024px) {
      padding: 1.5rem 0 0;
      height: 450px;
    }
    @media (max-width: 550px) {
      padding: 0 0 0;
    }
    @media (max-width: 500px) {
      height: 500px;
      padding: 0 0 15px;
      object-fit: cover;
    }
  }
  video {
    width: 100%;
    padding: 1.75rem 0 0;
    @media (max-width: 1024px) {
      padding: 1.5rem 0 0;
    }
  }
  .height_video {
    height: 800px;
    padding: 1.75rem 0 0;
    @media (max-width: 1024px) {
      padding: 1.5rem 0 0;
      height: 450px;
    }
    @media (max-width: 550px) {
      height: 700px;
      margin: 0px 0px 15px;
      padding: 0;
    }
    a {
      position: absolute;
      width: 100%;
      height: 100%;
      z-index: 99999;
    }
    .player-wrapper {
      cursor: pointer;
    }
  }
`;
const Gallery360 = styled.div`
  width: 100%;
  padding: 1.75rem 0 0;
  .contenedor_360 {
    width: 100%;
    height: 650px;
    padding-bottom: 1.75rem;
  }
  .pnlm-container {
    width: 100% !important;
    height: 100% !important;
  }

  .height_iframe {
    height: 800px;
    padding: 1.75rem 0 0;
    iframe {
      width: 100%;
      height: 100%;
    }
  }
`;
const NewsArticles = styled.div`
  width: 100%;
  /* height: 1200px; */
  .content-card {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    column-gap: 40px;
    row-gap: 40px;
    background-color: #f1f0eb;
    margin-top: 1.75rem;
    padding: 50px;
    @media (max-width: 1024px) {
      padding: 40px;
    }
    .card {
      width: 48.5%;
      height: auto;
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      @media (max-width: 1024px) {
        width: 100%;
      }

      .img {
        height: 400px;
        padding-bottom: 15px;
        @media (min-width: 1600px) {
          height: 470px;
        }
        /* @media (max-width: 550px) {
                    height: 320px;
                } */
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          cursor: pointer;
        }
      }
      a {
        font-family: HelveticaNeuemedium;
        font-size: 23px;
        font-weight: 700;
        background-color: transparent;
        color: black;
        z-index: 1;
        cursor: pointer;
        @media (max-width: 550px) {
          font-size: 18px;
        }
      }
      .link-subrayado {
        text-decoration: none;
        color: black;
        font-weight: 700;
        vertical-align: top;
        background-image: linear-gradient(transparent 20%, var(--link-2) 10%);
        background-size: auto 175%;
        transition: background 0.5s ease-in-out;
        &:hover {
          background-position-y: 100%;
        }
      }
    }
  }
`;
const GalleryBranding = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-auto-rows: 600px;
  gap: 1.75rem;
  padding: 1.75rem 0;
  @media (min-width: 1600px) {
    grid-auto-rows: 750px;
  }
  @media (max-width: 1024px) {
    grid-auto-rows: 350px;
  }
  @media (max-width: 550px) {
    display: inline-block;
    grid-auto-rows: 180px;
    padding: 0px 15px 15px;
    gap: 15px;
  }
  @media (max-width: 390px) {
    grid-auto-rows: 150px;
  }
  .span-1 {
    grid-column: span 1;
  }
  .span-2 {
    grid-column: span 2;
  }
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    @media (max-width: 550px) {
      padding-bottom: 15px;
    }
  }
`;
const TitledFrontPageStyled = styled.div`
  ${PortadaV2} {
    @media (max-width: 1024px) {
      height: 500px;
    }
    @media (max-width: 550px) {
      min-height: 550px;
    }
    @media (max-width: 390px) {
      min-height: 400px;
      height: 400px;
    }
    img {
      @media (max-width: 1024px) {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .content {
      @media (max-width: 1024px) {
        justify-content: end;
      }
      @media (max-width: 550px) {
        padding-bottom: 35px;
      }
    }
    ${({ $hideTitleOnDesktop }) =>
      $hideTitleOnDesktop &&
      css`
        .title h1 {
          @media (min-width: 1024px) {
            display: none;
          }
        }
      `}
    .title {
      h1 {
        @media (max-width: 1024px) {
          font-size: 50px;
        }
        @media (max-width: 390px) {
          font-size: 42px;
        }
      }
    }
    h2 {
      @media (max-width: 1024px) {
        font-size: 50px;
      }
      @media (max-width: 390px) {
        font-size: 42px;
      }
    }
  }
`;
export {
  Portada,
  PortadaV2,
  Concepto,
  Filtrado,
  GaleryGrid,
  Galleryv2,
  Gallery360,
  NewsArticles,
  TitledFrontPageStyled,
  GalleryBranding,
};
