export const porjectsBranding = [
  {
    id: 1,
    category: "Real-Estate-Branding-Services",
    path: "Summa",
    name: "Summa",
    imgCover: "/assets/img/services/Galeria/Branding/summa/portada-summa.png",
    imgCard: "/assets/img/services/Galeria/Branding/summa/summa.png",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/summa/portadaDetail.png",
    column: "2",
    columnAll: "2",
    overlay: false,
    sections: [
      //De acuerdo a las secciones que vayan a existir en el detalle, párrafos con textos y galeria de imagenes
      {
        type: "paragraph",
        content: [
          {
            className: "w50",
            description: [
              {
                font: "bold",
                text: "Introducing Summa",
              },
              {
                font: "normal",
                text: "Bal Harbour's Exquisite",
              },
              {
                font: "normal",
                text: "Waterfront Condo Development",
              },
            ],
          },
          {
            className: "w50",
            line: true, //Ya que una seccion lleva una linea sobre el texto se contempla aqui
            description: [
              {
                font: "mini",
                text: "Nestled along the prestigious Collins Avenue in Bal Harbour, Summa stands as an extraordinary testament to luxury living. ",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img1.png",
            column: "4", //Se contempla un grid de 4 columnas máximo
            row: "1",
          },
          {
            type: "video",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/video1.mp4", //Los videos estan pendientes pero se agregan para mantener la estructura y pruebas
            column: "1",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img2.png",
            column: "3",
            row: "1",
          },
          {
            type: "video",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/video2.mp4",
            column: "4",
            row: "1",
            style: { objectPosition: "left" },
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img3.png",
            column: "3",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img4.png",
            column: "1",
            row: "1",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            className: "w55 desk-w45", //Se agrega una clase para pantallas grandes
            description: [
              {
                font: "normal",
                text: "This exclusive waterfront condo development redefines opulence, offering a remarkable residential experience in one of the world's most coveted coastal destinations. ",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img5.png",
            column: "4",
            row: "1",
          },
          {
            type: "video",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/video3.mp4",
            column: "4",
            row: "1",
            style: {
              objectPosition: "left",
            },
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img6.png",
            column: "1",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img7.png",
            column: "2",
            row: "1",
            style: {
              objectFit: "fill",
            },
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img8.png",
            column: "1",
            row: "1",
            style: {
              objectFit: "fill",
            },
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            className: "w45 desk-w34",
            description: [
              {
                font: "normal",
                text: "Summa represents the pinnacle of luxury waterfront living, where sophistication meets tranquility.",
              },
            ],
          },
          {
            className: "w45",
            description: [
              {
                font: "mini",
                text: "Immerse yourself in a world of timeless elegance, impeccable craftsmanship, and unparalleled beauty at Summa.",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "video",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/video4.mp4",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img9.png",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img10.png",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img11.png",
            column: "2",
            row: "1",
          },
          {
            type: "video",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/video5.mp4",
            column: "2",
            row: "1",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            className: "w45 desk-w39",
            description: [
              {
                font: "normal",
                text: "Discover the epitome of Bal Harbour living and experience a life of grandeur and refinement like no other.",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img12.png",
            column: "1",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img13.png",
            column: "3",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img14.png",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img15.png",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img16.png",
            column: "2",
            row: "1",
          },
          {
            type: "video",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/video6.mp4",
            column: "2",
            row: "1",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            className: "w35 desk-w30",
            description: [
              {
                font: "mini",
                text: "With a commanding presence along the coastline, Summa's architectural brilliance captivates the eye and delights the senses.",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img17.png",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img18.png",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/summa/galeria/img19.png",
            column: "2",
            row: "1",
          },
        ],
      },
      {
        type: "paragraph",
        content: [
          {
            className: "w60 desk-w50",
            description: [
              {
                font: "mini",
                text: "Its sleek and modern design seamlessly blends with the surrounding natural beauty, creating a harmonious fusion of style and serenity. Floor-to-ceiling windows bathe the interiors in abundant natural light, offering breathtaking panoramic views of the Atlantic Ocean and the vibrant Miami skyline.",
              },
            ],
          },
        ],
      },
    ],
  },
  {
    id: 2,
    category: "Real-Estate-Branding-Services",
    path: "the-district",
    name: "The District",
    imgCover: "/assets/img/services/Galeria/Branding/theDistrict/portada.jpeg",
    imgCard: "/assets/img/services/Galeria/Branding/theDistrict/portada.jpeg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/theDistrict/portada.jpeg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w80",
            description: [
              {
                font: "title",
                text: "The District",
              },
              {
                font: "mini",
                text: "Concept, graphic and brand development for one of the largest and most recent mixed-use developments in Guadalajara, designed by Sordo Madaleno. The challenge was to create a versatile brand that spoke to the variety of markets the structure caters to - residential, corporate and commercial. With simple elements and nuanced colors, we managed to reflect the many functions of this mixed-use development under a single communication tone that not only encompasses all of its parts but integrates them, establishing The District as a modern space defined by sophistication.",
              },
              {
                font: "mini-pad-top",
                text: "Naming | Logo | Editorial Design | Rendering | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img1.jpeg",
            column: "4", //Se contempla un grid de 4 columnas máximo
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img2.jpeg", //Los videos estan pendientes pero se agregan para mantener la estructura y pruebas
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img3.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img4.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img5.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img7.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img6.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img8.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img9.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theDistrict/img10.jpeg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 3,
    category: "Real-Estate-Branding-Services",
    path: "la-pedrera",
    name: "La Pedrera",
    imgCover: "/assets/img/services/Galeria/Branding/laPedrera/portada.jpeg",
    imgCard: "/assets/img/services/Galeria/Branding/laPedrera/portada.jpeg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/laPedrera/portadaDetail.jpeg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w80",
            description: [
              {
                font: "title",
                text: "La Pedrera",
              },
              {
                font: "mini",
                text: "A residential development in Austin, TX that called for a concept and graphic identity that would reflect the exquisite architecture and elevated lifestyle specially crafted for this lush terrain. For this project, we created a system that coexists in harmony with the project’s architectural design and natural splendor; resulting in La Pedrera: a sanctuary of tranquility among nature.",
              },
              {
                font: "mini-pad-top",
                text: "Naming | Logo | Editorial Design | Rendering | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img1.jpeg",
            column: "4", //Se contempla un grid de 4 columnas máximo
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img2.jpeg", //Los videos estan pendientes pero se agregan para mantener la estructura y pruebas
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img3.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img4.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img5.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img10.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img6.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img7.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img8.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/laPedrera/img9.jpeg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 4,
    category: "Real-Estate-Branding-Services",
    path: "the-schwarzman-group",
    name: "The Schwarzman Group",
    imgCover:
      "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/portada.jpeg",
    imgCard:
      "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/portada.jpeg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/portadaDetail.jpg",
    column: "2",
    columnAll: "2",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w80",
            description: [
              {
                font: "title",
                text: "The Schwarzman Group",
              },
              {
                font: "mini",
                text: "Rebranding is like giving a company a makeover—it's a strategic process of reinventing its identity to align with its evolving goals, values, and target audience. The Schwarzman Group opened its doors in 1983; in 2024, we gave it a new life as a brand, revitalizing its image and reinvigorating its connection with customers. This was not just a fresh look; this was about telling a new story and embracing change for a brighter future.",
              },
              {
                font: "mini-pad-top",
                text: "The Schwarzman Group is a renowned and accomplished real estate brand operating in major cities such as New York, Miami, The Hamptons, and Los Angeles; recognized for its unparalleled client service, innovative marketing strategies, ethical business practices, and outstanding sales achievements.",
              },
              {
                font: "mini-pad-top",
                text: "Rebranding | Logo | Editorial Design | Rendering | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img1.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img2.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img3.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img4.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img5.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img6.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img7.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img8.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img9.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/portada.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/theSchwarzmanGroup/img11.jpg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 5,
    category: "Start-Up-Branding-Services",
    path: "blubear",
    name: "Blubear",
    imgCover: "/assets/img/services/Galeria/Branding/blubear/portada.jpg",
    imgCard: "/assets/img/services/Galeria/Branding/blubear/portada.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/blubear/portadaDetail.jpeg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w80",
            description: [
              {
                font: "title",
                text: "Blubear",
              },
              {
                font: "mini",
                text: "Blubear is a Jackson Hole-based company that brings Mexican design to the U.S., combining tradition and innovation to create custom-made millwork and furniture specially tailored to meet the specific needs of their clients. For this very unique business, we were tasked to create an identity system that subtly complements photos of their beautiful work, as well as the branding belonging to the Mexico City-based interior design studio they are partnered with, Direccion. The result: a simply elegant graphic identity that acts as the perfect backdrop to Blubear's stunning projects.",
              },
              {
                font: "mini-pad-top",
                text: "Identity System | Logo | Editorial Design | Rendering | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img1.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img2.jpeg", //Los videos estan pendientes pero se agregan para mantener la estructura y pruebas
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img3.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img4.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img5.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img6.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img7.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img8.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img9.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blubear/img10.jpeg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 6,
    category: "Start-Up-Branding-Services",
    path: "chisel-collective",
    name: "Chisel Collective",
    imgCover:
      "/assets/img/services/Galeria/Branding/chiselCollective/portada.jpeg",
    imgCard:
      "/assets/img/services/Galeria/Branding/chiselCollective/portada.jpeg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/chiselCollective/portadaDetail.jpeg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w85",
            description: [
              {
                font: "title",
                text: "Chisel Collective",
              },
              {
                font: "mini",
                text: "Chisel Collective is a concept conceived by life coach Corey Anker that is much more than a personal brand - it's a community of individuals united by the desire to be the best version of themselves that they can possibly be.",
              },
              {
                font: "mini-pad-top",
                text: "Our client came to us with many ideas, and our job was to bring those ideas to life in a way that would allow them to keep growing along with the community.",
              },
              {
                font: "mini-pad-top",
                text: "Identity System | Logo | Rendering | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/chiselCollective/portada.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/chiselCollective/img1.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/chiselCollective/img2.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/chiselCollective/img3.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/chiselCollective/img4.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/chiselCollective/img5.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/chiselCollective/img6.jpeg",
            column: "2",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 7,
    category: "Real-Estate-Branding-Services",
    path: "st-elmo-hotel",
    name: "St Elmo Hotel",
    imgCover: "/assets/img/services/Galeria/Branding/stElmo/portada.jpg",
    imgCard: "/assets/img/services/Galeria/Branding/stElmo/portada.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/stElmo/portadaDetail.jpeg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w85",
            description: [
              {
                font: "title",
                text: "St. Elmo Hotel",
              },
              {
                font: "mini",
                text: "A completely made-from-scratch brand for a hotel in an up-and-coming area of Austin, Texas in the process of being converted from a chain hotel to a boutique hotel. We explored the surrounding area and its history to come up with a creative concept that would fit our clients' vision. We were thrilled to be a part of the hotel's make-over process; to establish a bold new concept, identity and personality for it that makes much more sense than its former brand in a city as quirky, colorful and cool as Austin.",
              },
              {
                font: "mini-pad-top",
                text: "Naming | Creative Concept | Identity System | Logo | Rendering | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/stElmo/img1.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/stElmo/img2.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/stElmo/img3.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/stElmo/img4.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/stElmo/img5.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/stElmo/img6.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/stElmo/img7.jpeg",
            column: "2",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 8,
    category: "Start-Up-Branding-Services",
    path: "cedric-salon",
    name: "Cedric Salon",
    imgCover: "/assets/img/services/Galeria/Branding/cedric/portada.jpg",
    imgCard: "/assets/img/services/Galeria/Branding/cedric/portada.jpg",
    imgCoverDetail: "/assets/img/services/Galeria/Branding/cedric/portada.jpg",
    column: "2",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w85",
            description: [
              {
                font: "title",
                text: "Cédric Salon",
              },
              {
                font: "mini",
                text: "Bare-bones branding for a high-end hair salon located inside the Lotte New York Palace Hotel in Midtown Manhattan. ",
              },
              {
                font: "mini-pad-top",
                text: "A simple but elegant and to-the-point brand identity was developed, all in line with a concept that clearly defines what the Cèdric brand is. Careful attention to uniformity creates a strong personality that simultaneously stands out and fits in among the city’s high-energy hustle and bustle.",
              },
              {
                font: "mini-pad-top",
                text: "Identity System | Logo | Rendering | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/cedric/img1.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/cedric/img2.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/cedric/img3.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/cedric/img4.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/cedric/img5.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/cedric/img6.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/cedric/img7.jpeg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 9,
    category: "Real-Estate-Branding-Services",
    path: "caretta",
    name: "Caretta",
    imgCover: "/assets/img/services/Galeria/Branding/caretta/portada.jpg",
    imgCard: "/assets/img/services/Galeria/Branding/caretta/portada.jpeg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/caretta/portada.jpeg",
    column: "1",
    columnAll: "2", //Agrego esta columna ya que se pierde la estructura cuando se filtra por categoria
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w85",
            description: [
              {
                font: "title",
                text: "Caretta",
              },
              {
                font: "mini",
                text: "For this resort-style home rental in Cabo, we felt that Caretta, the scientific name for the loggerhead turtles that periodically use Cabo’s shores as a brief refuge, was the perfect name; and would speak to a market that would essentially be doing the same. After first developing a captivating concept from which Caretta’s storytelling naturally flowed, we were able to organically refine all of the brand’s individual elements, from its graphic identity to its communication, creating a brand that is at once refreshing and refined.",
              },
              {
                font: "mini-pad-top",
                text: "Naming | Logo | Editorial Design | Rendering",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/caretta/img1.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/caretta/img2.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/caretta/img3.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/caretta/img4.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/caretta/img5.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/caretta/img6.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/caretta/img7.jpeg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 10,
    category: "Start-Up-Branding-Services",
    path: "blue-star",
    name: "Blue Star",
    imgCover: "/assets/img/services/Galeria/Branding/blueStar/portada.jpeg",
    imgCard: "/assets/img/services/Galeria/Branding/blueStar/portada.jpeg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/blueStar/portadaDetail.jpeg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w85",
            description: [
              {
                font: "title",
                text: "Blue Star",
              },
              {
                font: "mini",
                text: "As San Antonio's longest-existing craft brewery, the Blue Star Brewing Company found itself at a point where it was ready to take the next step and bottle its much-loved brews.",
              },
              {
                font: "mini-pad-top",
                text: "Of course, this meant creating a fresh brand identity for its packaging that could extend itself to its many varieties of beer.",
              },
              {
                font: "mini-pad-top",
                text: "Taking inspiration from San Antonio's unique history and charm, we created a packaging system that preserves each beer's individual identity while maintaining a cohesive quality unique to the Blue Star Brewing Company brand.",
              },
              {
                font: "mini-pad-top",
                text: "Identity System | Logo | Product Packaging | Rendering",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blueStar/img1.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blueStar/img2.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blueStar/portada.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blueStar/img3.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blueStar/img4.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blueStar/img5.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/blueStar/img6.jpeg",
            column: "2",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 11,
    category: "Start-Up-Branding-Services",
    path: "mudras",
    name: "Mudras",
    imgCover: "/assets/img/services/Galeria/Branding/mudras/portada.jpeg",
    imgCard: "/assets/img/services/Galeria/Branding/mudras/portada.jpeg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/mudras/portadaDetail.jpg",
    column: "1",
    columnAll: "1",
    overlay: false,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w85",
            description: [
              {
                font: "title",
                text: "Mudras",
              },
              {
                font: "mini",
                text: "On the brink of launching a new strategy by which to fine-tune their target market and achieve greater goals, Mudras no longer felt effectively represented by their existing brand.",
              },
              {
                font: "mini-pad-top",
                text: "We listened to Mudras’s wants and needs, truly understanding what they desired to achieve, in order to create a new identity for the Mudras brand, with a personality to match. This included creating an entirely new logo, along with graphic and branding guidelines that carefully define a variety of elements, from typography to color scheme to the language that would be used throughout their communication.",
              },
              {
                font: "mini-pad-top",
                text: "Identity System | Logo | Editorial Design | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/mudras/portada.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/mudras/img1.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/mudras/img2.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/mudras/img3.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/mudras/img4.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/mudras/img5.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/mudras/img6.jpeg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 12,
    category: "Real-Estate-Branding-Services",
    path: "el-tecuan",
    name: "El Tecuán",
    imgCover: "/assets/img/services/Galeria/Branding/elTecuan/portada.jpg",
    imgCard: "/assets/img/services/Galeria/Branding/elTecuan/portada.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/elTecuan/portada.jpg",
    column: "2",
    columnAll: "2",
    overlay: false,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w85",
            description: [
              {
                font: "title",
                text: "El Tecuán",
              },
              {
                font: "mini",
                text: "For this resort-style residential development in a virtually unexplored area of Mexico’s Costa Alegre named El Tecuán, we developed a concept and graphic identity inspired by the colors and terrain of the land itself. The goal was to complement the unique architectural forms that were specially designed for El Tecuán, as well as the lush landscapes that define every element of its identity and communication.",
              },
              {
                font: "mini-pad-top",
                text: "Identity System | Logo | Editorial Design | Rendering | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img1.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img2.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img3.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img4.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img5.jpg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img6.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img7.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img8.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img9.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/elTecuan/img10.jpeg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 13,
    category: "Start-Up-Branding-Services",
    path: "rootmate",
    name: "Rootmate",
    imgCover: "/assets/img/services/Galeria/Branding/rootmate/portada.jpeg",
    imgCard: "/assets/img/services/Galeria/Branding/rootmate/portada.jpeg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/rootmate/portada.jpeg",
    column: "2",
    columnAll: "2",
    overlay: true,
    sections: [
      {
        type: "paragraph",
        backgroundColor: "gray",
        content: [
          {
            className: "w85",
            description: [
              {
                font: "title",
                text: "Rootmate",
              },
              {
                font: "mini",
                text: "We loved creating the brand for this line of beautifully designed planters developed by an interior designer who noticed a need for more tasteful options when it came to outfitting indoor plants.",
              },
              {
                font: "mini-pad-top",
                text: "Our research allowed us to take advantage of trends in order to create a brand name, creative concept and graphic identity that especially clicked with the market these products were developed for.",
              },
              {
                font: "mini-pad-top",
                text: "Naming | Logo | Editorial Design | Collateral",
              },
            ],
          },
        ],
      },
      {
        type: "gallery",
        content: [
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/rootmate/img1.jpeg",
            column: "4",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/rootmate/img2.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/rootmate/img3.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/rootmate/img4.jpeg",
            column: "2",
            row: "2",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/rootmate/img5.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/rootmate/img6.jpeg",
            column: "2",
            row: "1",
          },
          {
            type: "img",
            url: "/assets/img/services/Galeria/Branding/rootmate/img7.jpg",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 14,
    category: "Marketing-Material-Branding-Services",
    path: "486-broadway",
    name: "486 Broadway",
    imgCover:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/1.486-Broadway.jpg",
    imgCard:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/1.486-Broadway.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/1.486-Broadway.jpg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "gallery",
        content: [
          {
            type: "iframe",
            url: "https://heyzine.com/flip-book/8ccb093230.html",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 15,
    category: "Marketing-Material-Branding-Services",
    path: "793-madison-avenue",
    name: "793 Madison Avenue",
    imgCover:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/2.793-Madison.jpg",
    imgCard:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/2.793-Madison.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/2.793-Madison.jpg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "gallery",
        content: [
          {
            type: "iframe",
            url: "https://heyzine.com/flip-book/fa31f27f1b.html",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 16,
    category: "Marketing-Material-Branding-Services",
    path: "fordham-university",
    name: "Fordham University",
    imgCover:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/3.Fordham.jpg",
    imgCard:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/3.Fordham.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/3.Fordham.jpg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "gallery",
        content: [
          {
            type: "iframe",
            url: "https://heyzine.com/flip-book/f4446974b6.html",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 17,
    category: "Marketing-Material-Branding-Services",
    path: "115th-street-community-center",
    name: "115th Street ",
    imgCover:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/4.115th.jpg",
    imgCard:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/4.115th.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/4.115th.jpg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "gallery",
        content: [
          {
            type: "iframe",
            url: "https://heyzine.com/flip-book/47795ea9ba.html",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 18,
    category: "Marketing-Material-Branding-Services",
    path: "promile",
    name: "Promile",
    imgCover:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/5.Promile 2.jpg",
    imgCard:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/5.Promile 2.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/5.Promile 2.jpg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "gallery",
        content: [
          {
            type: "vimeo",
            url: "https://vimeo.com/948439537",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
  {
    id: 19,
    category: "Marketing-Material-Branding-Services",
    path: "bal-harbour",
    name: "Bal Harbour",
    imgCover:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/6.BalHarbour.jpg",
    imgCard:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/6.BalHarbour.jpg",
    imgCoverDetail:
      "/assets/img/services/Galeria/Branding/marketing-material/Portadas/6.BalHarbour.jpg",
    column: "1",
    columnAll: "1",
    overlay: true,
    sections: [
      {
        type: "gallery",
        content: [
          {
            type: "iframe",
            url: "https://heyzine.com/flip-book/cadaa46c19.html",
            column: "4",
            row: "1",
          },
        ],
      },
    ],
  },
];
