import { CiCirclePlus } from "react-icons/ci";
import { FaqListItemStyled } from "./FaqListItem.styled";

export const FaqListItem = ({ title, children }) => {
  return (
    <FaqListItemStyled>
      <span>
        {title}
        <CiCirclePlus size={22} />
      </span>
      <div>{children}</div>
    </FaqListItemStyled>
  );
};
