import React, { useEffect, useState, useCallback } from "react";
import Header from "./Components/Header";
import Home from "./Pages/Home";
import { Routes, Route } from "react-router-dom";
import { Loading, StikyTop } from "./Styles/Styles";
import "./App.css";
import Footer from "./Components/Footer";
import Formulario from "./Components/Formulario";
import Modal from "./Components/Modal";
import { CSSTransition } from "react-transition-group";
import Rendering from "./Pages/Services/Rendering";
import Animation from "./Pages/Services/Animation";
import VirtualReality from "./Pages/Services/VirtualReality";
import Interactive from "./Pages/Services/Interactive";
import Branding from "./Pages/Services/Branding";
import VFX from "./Pages/Services/VFX";
import About from "./Pages/About";
import Contact from "./Pages/Contact";
import PoliticaPrivacidad from "./Components/PoliticaPrivacidad";
import Thanks from "./Pages/Thanks";
import ServiceDetail from "./Pages/Services/ServiceDetail";
import News from "./Pages/News";
import Mapa from "./Components/Mapa";
import TermsPrivacy from "./Pages/TermsPrivacy";
import ArticleDetail from "./Pages/ArticleDetail";
import Redirect from "./Components/Redirect";
import { GetAQuote } from "./Components/GetAQuote/GetAQuote";
import BrandingDetail from "./Pages/Services/BrandingDetail";
import { Helmet } from "react-helmet";
function App() {
  const [hide, sethide] = useState("");
  const [open, setOpen] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showModalTerms, setShowModalTerms] = useState(false);
  const [play, setPlay] = useState(false);

  // var pathname = window.location.pathname;
  useEffect(() => {
    setTimeout(() => {
      sethide("hide");
    }, 3000);
  }, []);
  const goToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  return (
    <div className={`App ${open}`}>
      <Helmet>
        <meta
          name="description"
          content="A New York City creative studio specializing in 3D rendering, branding, and marketing campaigns for real estate development, architecture and design. T. 212-203-7343"
        />
      </Helmet>
      <StikyTop onClick={() => goToTop()}>
        <svg
          version="1.1"
          id="Ebene_1"
          xmlns="http://www.w3.org/2000/svg"
          x="0px"
          y="0px"
          width="53px"
          height="20px"
          viewBox="0 0 53 20"
          enableBackground="new 0 0 53 20"
        >
          <g id="Ebene_3"></g>
          <g>
            <polygon points="43.886,16.221 42.697,17.687 26.5,4.731 10.303,17.688 9.114,16.221 26.5,2.312 	"></polygon>
          </g>
        </svg>
      </StikyTop>
      <CSSTransition
        in={showModal}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <Formulario showModal={showModal} setShowModal={setShowModal} />
      </CSSTransition>
      <CSSTransition
        in={showModalTerms}
        timeout={200}
        classNames="show"
        unmountOnExit
      >
        <PoliticaPrivacidad
          showModalTerms={showModalTerms}
          setShowModalTerms={setShowModalTerms}
        />
      </CSSTransition>
      <CSSTransition in={play} timeout={200} classNames="show" unmountOnExit>
        <Modal play={play} setPlay={setPlay} />
      </CSSTransition>
      <Header
        open={open}
        setOpen={setOpen}
        setShowModal={setShowModal}
      ></Header>
      <Routes>
        <Route path="*" element={<Redirect />} />
        <Route
          path="/"
          element={<Home setShowModal={setShowModal} setPlay={setPlay} />}
        />
        <Route
          path="/Photorealistic-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          path="/Animation-Services"
          element={<Animation></Animation>}
        ></Route>
        <Route
          path="/Virtual-Reality-Services"
          element={<VirtualReality></VirtualReality>}
        ></Route>
        <Route
          path="/Interactive-Services"
          element={<Interactive></Interactive>}
        ></Route>
        <Route
          path="/Branding-Services"
          element={<Branding></Branding>}
        ></Route>
        <Route
          path="/Branding-Services/:brandingPath"
          element={<BrandingDetail></BrandingDetail>}
        ></Route>
        <Route
          path="/Real-Estate-Branding-Services"
          element={<Branding></Branding>}
        ></Route>
        <Route
          path="/Start-Up-Branding-Services"
          element={<Branding></Branding>}
        ></Route>
        <Route
          path="/Marketing-Material-Branding-Services"
          element={<Branding></Branding>}
        ></Route>
        <Route path="/VFX-Services" element={<VFX></VFX>}></Route>
        <Route path="/About" element={<About></About>}></Route>
        <Route path="/Contact" element={<Contact></Contact>}></Route>
        <Route path="/ThankYou" element={<Thanks></Thanks>}></Route>
        <Route
          path="/VFX/:serviceId"
          element={<ServiceDetail></ServiceDetail>}
        ></Route>
        <Route
          path="/Animation/:serviceId"
          element={<ServiceDetail></ServiceDetail>}
        ></Route>
        <Route
          path="/VirtualReality/:serviceId"
          element={<ServiceDetail></ServiceDetail>}
        ></Route>
        <Route
          path="/Terms-Privacy"
          element={<TermsPrivacy></TermsPrivacy>}
        ></Route>
        <Route path="/News" element={<News></News>}></Route>
        <Route path="/Mapa" element={<Mapa></Mapa>}></Route>
        <Route
          path="/Architectural-Animation-Services"
          element={<Animation></Animation>}
        ></Route>
        <Route
          path="/Product-Design-Animation-Services"
          element={<Animation></Animation>}
        ></Route>
        <Route
          path="/Virtual-Reality-First-Class"
          element={<VirtualReality></VirtualReality>}
        ></Route>
        <Route
          path="/Virtual-Reality-Business"
          element={<VirtualReality></VirtualReality>}
        ></Route>
        <Route
          path="/Virtual-Reality-Economy"
          element={<VirtualReality></VirtualReality>}
        ></Route>
        <Route path="/VFX-Commercial-Services" element={<VFX></VFX>}></Route>
        <Route path="/VFX-Social-Media-Services" element={<VFX></VFX>}></Route>
        <Route
          exact
          path="/News/:slug"
          element={<ArticleDetail></ArticleDetail>}
        ></Route>
        <Route
          exact
          path="/Residential-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Brownstone-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Apartments&Condo-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Penthouse-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Cabin-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Kitchen-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Living-Room-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Bedroom-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Bathroom-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Amenities-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Roof-Garden-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Hospitality-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Restaurant-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Commercial-Space-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Mixed-Use-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Retail-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Office-Space-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Real-Estate-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/DOB-Signage-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Aerial-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Event-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/School-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Car-Dealership-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Metaverse-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Religious-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Product-Design-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Axonometric-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
        <Route
          exact
          path="/Hand-Drawn-Rendering-Services"
          element={<Rendering></Rendering>}
        ></Route>
      </Routes>
      <Footer
        setShowModalTerms={setShowModalTerms}
        setShowModal={setShowModal}
      ></Footer>
      <GetAQuote />
    </div>
  );
}

export default App;
