import styled from "styled-components";
export const FaqListItemStyled = styled.li`
  list-style: none;
  font-size: 30px;
  font-weight: 600;
  font-family: "HelveticaNeue";
  cursor: pointer;
  display: flex;
  flex-direction: column;
  &::after {
    content: " ";
    margin: 20px 0;
    width: 100%;
    height: 1px;
    background-color: black;
  }
  @media (max-width: 1024px) {
    font-size: 18px;
  }
  @media (max-width: 550px) {
    font-size: 18px;
    line-height: 20px;
    padding: 0;
  }
  span {
    display: flex;
    justify-content: space-between;
    align-items: end;
    gap: 10px;
    font-weight: 400;
    font-family: "HelveticaNeueRoman";
    letter-spacing: -0.03em;
    @media (max-width: 550px) {
      font-weight: 400;
    }
    @media (max-width: 390px) {
      font-size: 16px;
    }
    br {
      display: none;
      @media (max-width: 550px) {
        display: block;
      }
    }
    svg {
      flex: none;
    }
  }
  div {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 1s ease;
    p {
      color: #6c6c6c;
      padding: 10px 0 50px;
      @media (max-width: 900px) {
        padding: 10px 0 10px;
        font-size: 16px;
      }
      @media (max-width: 550px) {
        padding: 10px 0 10px;
        font-size: 16px;
      }
    }
  }
  &:hover div {
    height: auto;
    max-height: 400px;
    /* &:nth-child(2){
                        max-height: 500px;
                    } */
  }
  &:nth-child(2) {
    div {
      p {
        padding: 0;
        &:nth-child(1) {
          padding: 10px 0 0;
        }
        &:nth-child(8) {
          padding: 0 0 50px;
        }
      }
    }
    &:hover {
      div {
        max-height: 500px;
        @media (max-width: 550px) {
          max-height: 600px;
        }
      }
    }
  }
`;
