import React, { useState, useEffect, useMemo } from "react";
import FrontPage from "../../Components/Services/FrontPage";
import Filtros from "../../Components/Services/Filtros";
import ConceptService from "../../Components/Services/ConceptService";
import { porjectsBranding } from "../../utils/services/Branding/projects";
import { Link, useLocation } from "react-router-dom";
import { GalleryBranding } from "../../Components/Services/style";
import { Helmet } from "react-helmet";
const Branding = () => {
  const [gallery, setGallery] = useState(porjectsBranding);
  const [hideSeparadorMovile, setHideSeparadorMovile] = useState(true);
  let { pathname } = useLocation();
  let lastCharacter = pathname.slice(-1);
  if (lastCharacter === "/") {
    pathname = pathname.slice(0, pathname.length - 1);
  }
  const filtrosBranding = [
    {
      title: "All",
      path: "/Branding-Services",
      imgCover:
        "/assets/img/services/Galeria/Branding/summa/Branding_Services-3D_World_renderings_Inc.png", //Aun no se tienen las portadas de las demas categorias
      altText: "Branding Services - 3D World renderings",
      description: [
        //Igual con el texto
        "Branding is an indispensable part of your real estate development and marketing ventures. That’s why we’ve created our own brand development method composed of four frameworks (Business, Creative, Narrative and Graphic) in order to find the best strategic approach suited to your goals. Whether you’re launching a new real estate development in the city that wants to stand out in the crowd or simply in need of a marketing tool for a new listing that’s about to go live, we create tailor-made brands and strategies for a luxury real estate market that are essential in pitching an idea, raising capital, and ensuring your project’s development.",
      ],
      separador: true,
    },
    {
      title: "Real Estate",
      path: "/Real-Estate-Branding-Services",
      imgCover:
        "/assets/img/services/Galeria/Branding/summa/Branding_Services-3D_World_renderings_Inc.png",
      altText: "Branding Services - 3D World renderings",
      description: [
        "Branding is an indispensable part of your real estate development and marketing ventures. That’s why we’ve created our own brand development method composed of four frameworks (Business, Creative, Narrative and Graphic) in order to find the best strategic approach suited to your goals. Whether you’re launching a new real estate development in the city that wants to stand out in the crowd or simply in need of a marketing tool for a new listing that’s about to go live, we create tailor-made brands and strategies for a luxury real estate market that are essential in pitching an idea, raising capital, and ensuring your project’s development.",
      ],
      separador: true,
    },
    {
      title: "Startup",
      path: "/Start-Up-Branding-Services",
      imgCover:
        "/assets/img/services/Galeria/Branding/summa/Branding_Services-3D_World_renderings_Inc.png",
      altText: "Branding Services - 3D World renderings",
      description: [
        "Branding is an indispensable part of your real estate development and marketing ventures. That’s why we’ve created our own brand development method composed of four frameworks (Business, Creative, Narrative and Graphic) in order to find the best strategic approach suited to your goals. Whether you’re launching a new real estate development in the city that wants to stand out in the crowd or simply in need of a marketing tool for a new listing that’s about to go live, we create tailor-made brands and strategies for a luxury real estate market that are essential in pitching an idea, raising capital, and ensuring your project’s development.",
      ],
      separador: hideSeparadorMovile,
    },
    {
      title: "Marketing Material", //Esta pendiente como se va a trabajar esta categoria
      path: "/Marketing-Material-Branding-Services",
      imgCover:
        "/assets/img/services/Galeria/Branding/summa/Branding_Services-3D_World_renderings_Inc.png",
      altText: "Branding Services - 3D World renderings",
      description: [
        "Branding is an indispensable part of your real estate development and marketing ventures. That’s why we’ve created our own brand development method composed of four frameworks (Business, Creative, Narrative and Graphic) in order to find the best strategic approach suited to your goals. Whether you’re launching a new real estate development in the city that wants to stand out in the crowd or simply in need of a marketing tool for a new listing that’s about to go live, we create tailor-made brands and strategies for a luxury real estate market that are essential in pitching an idea, raising capital, and ensuring your project’s development.",
      ],
      separador: false,
    },
  ];
  const filtro = filtrosBranding.filter((filtro) => filtro.path === pathname);
  const handleGallery = () => {
    if (pathname === "/Branding-Services") {
      setGallery(porjectsBranding); //Se muestran todos los proyectos sin categoria
    } else {
      const category = porjectsBranding.filter(
        (data) => data.category === pathname.slice(1)
      );
      setGallery(category); //Se filtran por categoria de acuerdo al pathname
    }
  };
  useEffect(() => {
    if (window.innerWidth <= 390) {
      setHideSeparadorMovile(false);
    } else {
      setHideSeparadorMovile(true);
    }
    const resize = () => {
      if (window.innerWidth <= 390) {
        setHideSeparadorMovile(false);
      } else {
        setHideSeparadorMovile(true);
      }
    };
    window.addEventListener("resize", resize);
    return () => {
      window.removeEventListener("resize", resize);
    };
  }, []);
  useEffect(() => {
    window.scrollTo(0, 0);
    handleGallery();
    if (window.innerWidth > 1024) {
      const activeNot = document.getElementsByClassName("activeService");
      if (activeNot.length === 1) {
        activeNot[0].classList.remove("activeService");
      }
      const active = document.getElementById("Branding-Services");
      active.classList.add("activeService");
      const active2 = document.getElementById("services");
      active2.classList.add("activeli");
    }
  }, [pathname]);
  const galleryContent = useMemo(() =>
    gallery.map(
      (img, index) => (
        <Link
          className={`span-${
            pathname.slice(1) === img.category ? img.column : img.columnAll
          }`}
          key={index}
          to={`/Branding-Services/${img.path}`}
        >
          <img src={img.imgCard} alt="" />
        </Link>
      ),
      [gallery]
    )
  );
  const renderConceptServicesContent = () => {
    return (
      <ConceptService>
        <div className="title">
          {filtro.map((value, index) => (
            <h2 key={index}>
              Our {value.title === "All" ? "Branding" : value.title}
            </h2>
          ))}
          <h3>Services</h3>
        </div>
        {filtro[0].description.map((value, index) => (
          <p key={index} className="branding">
            {value}
          </p>
        ))}
      </ConceptService>
    );
  };
  return (
    <div className="body-content">
      <Helmet>
        <title>Branding | 3D World renderings, Inc.</title>
        <meta
          name="description"
          content="Elevate your real estate ventures with our comprehensive brand development method. Tailor-made brands and strategies for luxury real estate that pitch ideas, raise capital, and ensure successful project development."
        />
      </Helmet>
      <FrontPage
        portada={filtro[0].imgCover}
        alt={filtro[0].altText}
        name={"Branding"}
        subtitule={"Services"}
      >
        {renderConceptServicesContent()}
      </FrontPage>
      {renderConceptServicesContent()}
      <Filtros branding={"branding"}>
        <div className="filters-container">
          <ul>
            {filtrosBranding.map((filter, index) => (
              <React.Fragment key={index}>
                <li
                  {...(pathname === filter.path && {
                    className: "active",
                  })}
                >
                  <Link to={filter.path}>{filter.title}</Link>
                </li>
                {filter.separador && <div></div>}
              </React.Fragment>
            ))}
          </ul>
        </div>
      </Filtros>
      <GalleryBranding>{galleryContent}</GalleryBranding>
    </div>
  );
};

export default Branding;
