import { FaqListStyled } from "./FaqList.styled";
import { FaqListItem } from "./FaqListItem";

export const FaqList = () => {
  return (
    <FaqListStyled>
      <FaqListItem
        title={
          <>
            What do you need to <br />
            start a project?
          </>
        }
      >
        <p>
          It begins with a conversation, whether it be by email, phone, video
          call, or in person, so that we can have a better understanding of your
          needs. Once you send us an information packet that could include CAD
          Files, a PDF with overall measurements, and/or a mood board, we will
          send you a formal proposal, as well as our working calendar for your
          revision. Our project management team will then contact you to
          schedule a kick-off meeting.
        </p>
      </FaqListItem>
      <FaqListItem
        title={
          <>
            How long does it take to produce <br /> your services from start to
            finish?
          </>
        }
      >
        <p>
          The time factor will always depend on the complexity of your project.
          Roughly speaking:
        </p>
        <p>
          – Renderings can take us from 4 to 6 days to develop from start to
          finish.
        </p>
        <p>
          – Animations take us from 5 to 8 weeks depending on complexity and any
          special requirements such as green screen, motion graphics, etc.
        </p>
        <p>
          – Virtual Reality can take us from 5 to 8 days to develop from start
          to finish.
        </p>
        <p>
          – For our interactive services, we offer a variety of different
          options so timelines vary. Please contact us directly for a formal
          calendar.
        </p>
        <p>
          – For our Photography services, we also offer a variety of modalities,
          so timelines vary. Please contact us directly for a formal calendar.
        </p>
        <p>
          – A Branding project can take us from 8 to 12 weeks to develop from
          start to finish, depending on the complexity of your project.
        </p>
        <p>
          -For our VFX services, we offer a variety of modalities, so timelines
          vary. Please contact us directly to discuss your needs and create a
          tailor-made calendar.
        </p>
      </FaqListItem>
      <FaqListItem
        title={
          <>
            How many preview rounds do I <br /> have for my project?
          </>
        }
      >
        <p>
          The rounds of markups and comments vary depending on the visual
          content we’re producing for your project. For each preview, there is
          no limit on the number of comments or markups you can make as long as
          they adhere to the work completed within that preview*. Please contact
          us directly for more information.
        </p>
      </FaqListItem>
      <FaqListItem
        title={
          <>
            I need my project done <br /> yesterday. Can you help?
          </>
        }
      >
        <p>
          Expedited project requests will be accepted on a per case basis and
          will be assessed based on the current availability and workflow our
          studio has at the moment of your inquiry.
        </p>
      </FaqListItem>
      <FaqListItem
        title={
          <>
            What services does your studio <br />
            offer?
          </>
        }
      >
        <p>
          3D World is a New York City creative studio specializing in 3D
          rendering, Animation, Virtual Reality, Interactive solutions,
          Branding, and VFX for real estate developers, architects, and
          advertising agencies.
        </p>
      </FaqListItem>
      <FaqListItem title="Who are some of your clients?">
        <p>
          Douglas Elliman, Compass, Corcoran Group, Sotheby’s, Terra CRG, Toll
          Brothers, Gensler, SOM, Rockwell Group, OMA, CWB architects, O’Neil
          Architects, Dash Design, Architectural Research Office, Starfish,
          e9Digital, Morris Adjmi Architects, Jeffrey Beers International, Glen
          & Co architecture, Wyndham, Le Meridien, Renaissance Hotels, Hyatt,
          Marriot, Hilton, Ollie, The Washington Post, WeWork, USGA, Lamer,
          Bulova, Kenneth Cole, Victoria’s Secret, Tory Burch, Godiva, Domino’s
          pizza, Starbucks, Cube smart, The Home Depot, Universal Studios,
          Republic records, DMA united, Resource Furniture, Air France, United
          Airlines to name a few.
        </p>
      </FaqListItem>
      <FaqListItem title="Where is your studio located?">
        <p>
          Our studio is located in Downtown Manhattan at 33 Irving Place 4th
          Floor, New York, NY 10003. Right next to the 14th St Union Square
          station. You can use the 4, 5, 6, L, N, Q, R, W trains to get here. We
          are open Monday to Friday, from 9 am to 6 pm. If you are not in New
          York, that’s not a problem. We offer virtual consultations to all our
          clientele reaching all corners of the map.
        </p>
      </FaqListItem>
      <FaqListItem
        title={
          <>
            What is the best way to get in <br /> touch with you?
          </>
        }
      >
        <p>
          The best way to contact us is by phone +1 212-203-7343, or by email
          info@3dworld.nyc, or at our Instagram @3dworldny – Drop us a line!
        </p>
      </FaqListItem>
      <FaqListItem
        title={
          <>
            Who are key business partners <br /> for 3DW?
          </>
        }
      >
        <p>
          3DW’s key business partners include real estate developers,
          architects, interior designers, contractors, branding and marketing
          agencies, and anyone looking to take his or her conceptual idea to the
          next level.
        </p>
      </FaqListItem>
      <FaqListItem
        title={
          <>
            What sentence would best
            <br /> describe 3DW's services?
          </>
        }
      >
        <p>
          A New York City creative studio specializing in 3D rendering,
          branding, and marketing campaigns that makes experiencing your ideas
          possible.
        </p>
      </FaqListItem>
    </FaqListStyled>
  );
};
