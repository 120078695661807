import React, { useState, useEffect } from "react";
import FrontPage from "../../Components/Services/FrontPage";
import Filtros from "../../Components/Services/Filtros";
import ConceptService from "../../Components/Services/ConceptService";
import GaleryVideo from "../../Components/Services/GaleryVideo";
import { galeriesVFX } from "../../utils/portaforio";
import { Link, useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
const VFX = () => {
  const [gallery, setGallery] = useState(galeriesVFX);
  let { pathname } = useLocation();
  const filtrosVFX = [
    {
      title: "All",
      link: "/VFX-Services",
    },
    {
      title: "Commercial",
      link: "/VFX-Commercial-Services",
    },
    {
      title: "Social Media",
      link: "/VFX-Social-Media-Services",
    },
  ];
  const filtro = filtrosVFX.filter((filtro) => filtro.link == pathname);
  useEffect(() => {
    if (pathname == "/VFX-Services") {
      setGallery(galeriesVFX);
    } else {
      setGallery(galeriesVFX.filter((filtro) => filtro.category == pathname));
    }
  });
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const renderConceptServicesContent = () => {
    return (
      <ConceptService>
        <div className="title">
          {filtro.map((value, index) => {
            return (
              <>
                {value.link == "/VFX-Services" && <h2 key={index}>Our VFX</h2>}
                {value.link !== "/VFX-Services" && (
                  <h2 key={index}>Our {value.title}</h2>
                )}
              </>
            );
          })}
          <h3>Services</h3>
        </div>
        <p className="vfx">
          Our Visual Effects or VFX service will help you create, modify, or
          enhance visual content by creating 3D models and animations,
          compositing footage, creating special effects, and more. Our team will
          collaborate closely with you to fully understand your needs and
          objectives. With this knowledge, we employ our technical know-how and
          advanced software tools to craft top-notch visual content that caters
          exclusively to your needs. Our VFX service will allow you to enhance
          your project’s visual appeal and value whether it be for a commercial,
          social media campaign, real estate presentation, or website - we’ve
          got you covered.
        </p>
      </ConceptService>
    );
  };
  return (
    <div className="body-content">
      <Helmet>
        <title>VFX | 3D World renderings, Inc.</title>
        <meta
          name="description"
          content="Elevate your visual content with our VFX service. From 3D models and animations to special effects, our team crafts top-notch visuals tailored to your needs. Enhance your project's appeal and value across commercials, social media campaigns, presentations, and websites."
        />
      </Helmet>
      <FrontPage
        alt={"VFX Services - 3D World renderings, Inc."}
        portada={
          "/assets/img/services/Galeria/VFX/VFX_Services-3D_World_rendering_Inc.jpg"
        }
        name={"VFX"}
        subtitule={"Services"}
      >
        {renderConceptServicesContent()}
      </FrontPage>
      {renderConceptServicesContent()}
      <Filtros>
        <div className="filters-container">
          <ul>
            {filtrosVFX.map((val, index) => {
              return (
                <>
                  {pathname == val.link && (
                    <>
                      <li key={index} className="active">
                        <Link to={val.link}>{val.title}</Link>
                      </li>
                      <div></div>
                    </>
                  )}
                  {pathname != val.link && (
                    <>
                      <li key={index}>
                        <Link to={val.link}>{val.title}</Link>
                      </li>
                      <div></div>
                    </>
                  )}
                </>
              );
            })}
          </ul>
        </div>
      </Filtros>
      <GaleryVideo type="VFX" gallery={gallery}></GaleryVideo>
    </div>
  );
};

export default VFX;
